<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="발란코드, 마스터ID를 입력해주세요" v-model="form.search" @keypress.enter.prevent.stop="list" autocomplete="off"
                      v-focus></b-form-input>
      </b-input-group>
      <b-row>
        <b-col class="mb-1" cols="12" md="12" xl="4">
          <template v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')">
            <shop-preset v-model="form.shop"></shop-preset>
          </template>
        </b-col>
        <b-col class="mb-1" cols="12" md="6" xl="4">
          <brand-preset class="" ref="bps" v-model="form.brand" :hideDisabled="true"></brand-preset>
        </b-col>
        <b-col class="mb-1" cols="12" md="6" xl="4">
          <category-preset class="" ref="cps" v-model="form.category"></category-preset>
        </b-col>
      </b-row>
      <!--
      <b-form inline class="py-2">
        <b-dropdown variant="primary" :text="dateKeyMap[form.dateKey]">
          <b-dropdown-item @click="form.dateKey = 'create_date'">등록일</b-dropdown-item>
        </b-dropdown>
        <date-from-to :from.sync="form.created_from" :to.sync="form.created_to" v-bind="{init: '1 week', twoDays: true, absMonth: 12, year: 5}" @enter="list()">
        </date-from-to>
      </b-form>
      -->
      <b-row class="pb-3">
        <b-col>
          <div class="flex-row flex-wrap d-flex justify-content-start mt-1">
            <template v-for="(f) in statusOptions">
              <div class="flex-grow-0 mb-1 mr-3" :key="f.key">
                <div><small class="mb-n2">{{ f.name }}</small>
                  <i v-if="f.question" class="fa fa-question-circle ml-1" v-b-tooltip="f.msg"></i>
                </div>
                <b-btn-group class="d-block">
                  <b-btn v-for="o in f.options" size="sm" :key="o.value"
                         :variant="form[f.key] === o.value ? (o.variant || (o.value === 'ALL' ? 'dark' : '')): 'light'"
                         @click="o.click ? o.click() : form[f.key] = o.value">
                    {{ o.text }}
                  </b-btn>
                </b-btn-group>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="flex-row flex-wrap d-flex justify-content-start mt-1">
            <template v-for="(f) in formFields">
              <div :style="{width: (f.width || 110) * (f.op === 'range' ? 1.8 : 1) + 'px'}" class="flex-grow-0 mb-1 mr-3" :key="f.name">
                <div><small class="mb-n2">{{ f.name }}</small></div>
                <b-input-group v-if="f.op === 'range'" size="sm">
                  <b-form-input v-if="f.type === 'number'" class="text-center" placeholder="n개 이상" v-model.number="form.fields[f.key].gte"
                                @keypress.enter="list()"></b-form-input>
                  <b-form-input v-else class="text-center" placeholder="시작값" v-model="form.fields[f.key].gte" @keypress.enter="list()"></b-form-input>
                  <b-input-group-append>
                    <b-button disabled><i class="fa fa-arrows-h"></i></b-button>
                  </b-input-group-append>
                  <b-form-input v-if="f.type === 'number'" class="text-center" placeholder="n개 이하" v-model.number="form.fields[f.key].lte"
                                @keypress.enter="list()"></b-form-input>
                  <b-form-input v-else class="text-center" placeholder="끝값" v-model="form.fields[f.key].lte" @keypress.enter="list()"></b-form-input>
                </b-input-group>
              </div>
            </template>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>limit</small><br/>
              <b-form-input class="text-center w-100px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.limit" @keypress.enter="list"></b-form-input>
            </div>

            <div class="flex-grow-0 mb-1 mr-2">
              <small>last goods_no</small><br/>
              <b-form-input class="text-center w-100px" size="sm" title="이어서 가지고 올 발란코드" v-model.number="form.last_goods_no" @keypress.enter="list"></b-form-input>            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col cols="12" md="6" xl="3">
          <small>발란코드</small>
          <div class="flex-row flex-wrap d-flex">
            <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.goods_no_include" placeholder="포함할 발란코드"></b-form-textarea>
            <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.goods_no_exclude" placeholder="제외할 발란코드"></b-form-textarea>
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="3">
          <small>파트너상품코드</small>
          <div class="flex-row flex-wrap d-flex">
            <b-form-textarea class="w-50" :rows="2" v-model="form.goods_id_include" placeholder="포함할 파트너상품코드"></b-form-textarea>
            <b-form-textarea class="w-50" :rows="2" v-model="form.goods_id_exclude" placeholder="제외할 파트너상품코드"></b-form-textarea>
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="3">
          <small>GM ID</small>
          <div class="flex-row flex-wrap d-flex">
            <b-form-textarea class="w-50" :rows="2" v-model="form.gm_id" placeholder="포함할 GM ID"></b-form-textarea>
            <b-form-textarea class="w-50" :rows="2" v-model="form.gm_id_exclude" placeholder="제외할 GM ID"></b-form-textarea>
          </div>
        </b-col>
      </b-row>

      <div class="mt-2 clearfix">
        <b-button class="" variant="primary" @click="list" :disabled="busy.list">
          검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="ml-1" variant="warning" @click="resetForm">초기화</b-button>
      </div>
    </b-card>
    <b-card>
      <header>
        <div class="clearfix pb-1">
          <span style="font-size: 20px">
            <b>상품 목록</b>
          </span>
        </div>
      </header>
      <div class="clearfix my-2">
        <b-button class="mr-2" size="sm" variant="secondary" @click="selectAllItem">전체 선택/해제</b-button>
        <b-button class="mr-2" size="sm" variant="primary" @click="showModal('PriceLimitModal')">가격 고정 생성</b-button>
        <div v-if="!form.minimal" class="pull-right">
          <xlsx :types="['xlsx']" :preFunc="preDown" size="sm" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="goodsPriceLimitList"></xlsx>
        </div>
      </div>
      <hot-table ref="hotTable" :settings="hotSettings"></hot-table>
    </b-card>
    <price-limit-modal ref="PriceLimitModal" v-bind="{modal, cfMap, shop, shopMap, list}"></price-limit-modal>
    <goods-price-modal ref="GoodsPriceModal" v-bind="{modal, cfMap, shop, shopMap, list}"></goods-price-modal>

    <!--    <goodPartner-modal ref="GoodPartnerModal" @reloadGoodPartner="list" v-bind="{modal, items}"></goodPartner-modal>-->
    <iframe ref="ifr" name="xlsx_frame" style="width:1px;height:1px;visibility:hidden"></iframe>
    <form :action="$api.getHost() + '/price/priceLimitGoods/xlsx'" ref="xlsx_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>
  </div>
</template>

<script>
import {HotTable} from "@handsontable/vue";
import xlsx from "@/views/modules/Xlsx";
import moment from "moment-timezone";
import {formOptionsPreset} from "@/shared/fields";
import dateFromTo from '@/views/modules/DateFromTo.vue'
import * as utils from "@/shared/utils";
import GoodsPriceModal from '@/views/price/PriceLimit/GoodsPriceModal.vue';
import PriceLimitModal from '@/views/price/PriceLimit/PriceLimitModal.vue';

export default {
  name: 'GoodsPriceLimitTab',
  title: '가격 고정 관리',
  //components: {xlsx, HotTable, GoodPartnerModal, dateFromTo},
  components: {xlsx, HotTable, dateFromTo, GoodsPriceModal, PriceLimitModal},
  data() {
    return {
      maxLimit: 25000,
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      cfMap: {},
      modal: {detail: false, dupGoods: false, help: false, create: false, goodsDetail: false},
      defaultForm: {
        shop: [],
        brand: [],
        category: [],
        fields: {
          tot_stock: {lte: '', gte: ''}
        },
        progress: 'ALL',
        limit: 100,
        skip: 0,
        isPriceLimiting: 'ALL',
        dateKey: 'create_date',
        // created_from: moment().add(-1, 'week').format('YYYY-MM-DD'),
        // created_to: moment().format('YYYY-MM-DD'),
        isPartnerAddAmount: 'ALL',
        isBalaanAddAmount: 'ALL',
        isCatalog: 'ALL',
        isBPrice: 'ALL',
        goods_status: 'registered',
        display_status: 'view',
        stock_status: 'normal',
        sortKey: 'goods_no',
        goods_no_include: '',
        goods_no_exclude: '',
        goods_id_include: '',
        goods_id_exclude: '',
        gm_id: '',
        gm_id_exclude: '',
        sortDir: 'desc',
        minimal: false,
        last_goods_no: null,
      },
      query: '',
      form: {},
      items: {list: []},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      xlsx: {
        selected: [],
        keys: [],
        labels: [],
      },
      colMap: {},
      months: [],
      month: moment().add("-1", "M").format('YYYY-MM'),
      updateDt: '',
      formFields: [
        {name: '총 재고', key: 'tot_stock', type: 'number', op: 'range', width: 110},
      ],
      statusOptions: [
        {
          name: '등록상태', key: 'goods_status', options: [
            {text: '전체', value: 'ALL'},
            {text: 'Processing', value: 'processing', variant: 'info'},
            {text: 'Registered', value: 'registered', variant: 'success'},
            {text: 'Terminated', value: 'terminated', variant: 'danger'}
          ]
        },
        {
          name: '노출상태', key: 'display_status', options: [
            {text: '전체', value: 'ALL'},
            {text: '노출', value: 'view', variant: 'success'},
            {text: '미노출', value: 'notview', variant: 'warning'}
          ]
        },
        {
          name: '재고상태', key: 'stock_status', options: [
            {text: '전체', value: 'ALL'},
            {text: '재고있음', value: 'normal', variant: 'success'},
            {text: '품절', value: 'runout', variant: 'warning'}
          ]
        },
        {
          name: '가격 고정 여부', key: 'isPriceLimiting', options: [
            {text: '전체', value: 'ALL'},
            {text: '고정 있음', value: 'y', variant: 'primary'},
            {text: '고정 없음', value: 'n', variant: 'primary'},
          ]
        },
        {
          name: '상품별 정액 할인(파트너 분담 여부)', question: true, msg: '필터 정확도가 불완전하여 ~3%의 확률로 검색한 조건과 다르게 보일 수 있습니다.', key: 'isPartnerAddAmount', options: [
            {text: '전체', value: 'ALL'},
            {text: '있음', value: 'y', variant: 'primary'},
            {text: '없음', value: 'n', variant: 'primary'},
          ]
        },
        {
          name: '상품별 정액 할인(발란 분담 여부)', question: true, msg: '필터 정확도가 불완전하여 ~3%의 확률로 검색한 조건과 다르게 보일 수 있습니다.', key: 'isBalaanAddAmount', options: [
            {text: '전체', value: 'ALL'},
            {text: '있음', value: 'y', variant: 'primary'},
            {text: '없음', value: 'n', variant: 'primary'},
          ]
        },
        {
          name: '카탈로그 매칭 여부', key: 'isCatalog', options: [
            {text: '전체', value: 'ALL'},
            {text: '매칭', value: 'temp_matched,confirmed', variant: 'primary'},
            {text: '매칭 안됨', value: 'not_matched,excl_five_minutes,excl_multioption,excl_single_partner,excl_wrong_brand,excl_etc', variant: 'primary'},
          ]
        },
        {
          name: 'B최저가 달성 여부', key: 'isBPrice', options: [
            {text: '전체', value: 'ALL'},
            {text: 'Y', value: true, variant: 'primary'},
            {text: 'N', value: false, variant: 'primary'},
          ]
        },
        {
          name: '정렬기준', key: 'sortKey', options: [
            {text: '발란코드', value: 'goods_no', variant: 'primary'},
            {text: 'GM 14일 거래액 순위 높은 순', value: 'gm_saleRank14d', variant: 'info'},
          ]
        },
        {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
      ],
      dateKeyMap: {
        create_date: '등록일',
      },
      hotSettings: {
        data: [],
        colHeaders: (index) => {
          if (index === 0 && !this.form.minimal) {
            return `${this.items.list.filter(e => e.selected).length}/${this.items.list.length}`;
          }
          return this.fields[this.form.minimal ? 'shortGoods': 'goods'].map(e => e.name)[index];
        },
        columns:  [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        width: '100%',
        height: 1000,
        fixedColumnsLeft: 1,
        rowHeights:  90,
        licenseKey: 'non-commercial-and-evaluation',
        className: 'htCenter htMiddle',
        afterScrollVertically: () => {
          const hot = this.$refs.hotTable.hotInstance;
          if (hot.getPlugin('autoRowSize').getLastVisibleRow() + 10 >= hot.countRows() && !this.busy.listmore && this.hasMore.list) { // 마지막 10 row 가 보인다면
            this.list(true);
          }
        },
      },
      fields: {
        goods: [
          {data: 'selected', type: 'checkbox'} ,
          {data: 'priceLimitNo',  name: '가격고정번호', width: 110, type: 'numeric', readOnly: true},
          {data: 'priceLimitDate',  name: '가격 고정 기간', width: 170, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              let e = this.items.list[cellProperties.row];
              td.className = 'htCenter htMiddle';
              td.innerHTML = e.priceLimitNo ? `${e.price_limit.st} ~<br>${e.price_limit.ed}` : '';
            }},
          {data: 'priceLimitName',  name: '가격 고정 사유', width: 200,readOnly: true},
          {data: 'img_urls',  name: '이미지', width: 80, readOnly: true, renderer: 'image', style:{maxHeight:60}},
          {data: 'goods_info',  name: '상품정보',width: 380, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              let e = this.items.list[cellProperties.row];
              let opt = e.options.filter(e=>!e.not_found);
              td.innerHTML = `<div class="mb-n1"><a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop}</a> <span class="badge badge-warning">${e.brand_nm}</span>`
                  + ` <span class="badge badge-light" title="${e.category}">${e.org_category}</span>`
                  + `</div>`
                  + `<div class="mb-n1"><a href="/#/goods/${e.goods_no}" class="badge badge-primary" target="_blank">${e.goods_no}</a> `
                  + `${e.goods_nm}`
                  + (e.manual ? ` <span class="badge alert-danger">파트너관리</span>` : '')
                  + `</div>`
                  + `<small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> `
                  + `${opt.slice(0,3).map(e=>
                      `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock > 0 ? 'success' : 'secondary'}" title="판매 가능수량">${e.stock}</span>`
                  ).join(' ')}${opt.length > 3 ? `<small> 외 ${opt.length - 3} 개 옵션</small>` : ''}`;
            }},
          {data: 'gm_id',  name: 'GM ID',width: 110, readOnly: true},
          {data: 'gm_salePriceAvg14d',  name: 'GM 14일 평균 거래액', width: 200,readOnly: true},
          {data: 'limitPrice',  name: '가격 고정가',width: 150, readOnly: true, renderer: 'comma'},
          {data: 'org_price',  name: '원본가',width: 100, readOnly: true, renderer: 'comma'},
          {data: 'localRatePrice',  name: '상시 할인할증',width: 110, readOnly: true, renderer: 'comma'},
          {data: 'standardPrice',  name: '판매기준가', width: 150, readOnly: true, renderer: 'comma'},
          {data: 'discountBasePartner',  name: '1차 상시할인<br>(파트너 분담)', width: 200, readOnly: true, renderer: 'comma'},
          {data: 'discountBaseBalaan',  name: '1차 상시할인<br>(발란 분담)', width: 200, readOnly: true, renderer: 'comma'},
          {data: 'discountCondPartner',  name: '2차 비상시할인<br>(파트너 분담)', width: 200, readOnly: true, renderer: 'comma'},
          {data: 'discountCondBalaan',  name: '2차 비상시할인<br>(발란 분담)', width: 200, readOnly: true, renderer: 'comma'},
          {data: 'discountOnlyBalaan',  name: '3차 발란 단독할인<br>(발란 분담)', width: 200, readOnly: true, renderer: 'comma'},
          {data: 'partnerAddAmount',  name: '상품별 정액 할인<br>(파트너 분담)',width: 200, readOnly: true, renderer: 'comma'},
          {data: 'balaanAddAmount',  name: '상품별 정액 할인<br>(발란 분담)',width: 200, readOnly: true, renderer: 'comma'},
          {data: 'price',  name: '발란회원가', width: 100,readOnly: true, renderer: 'comma'},
          {data: 'c_rank',  name: '카탈로그 랭킹', width: 100,readOnly: true},
          {data: 'minPriceAll',  name: '발란추천가 목표', width: 100,readOnly: true, renderer: 'comma'},
          {data: 'minPriceAllDiff',  name: '발란추천가<bR>목표 차액', width: 70,readOnly: true, renderer: 'comma'},
          {data: 'minPriceAllDiffPer',  name: '발란추천가 목표 차액 %', width: 70,readOnly: true},
          {data: 'b_min_price',  name: '단독 B최저가 목표', width: 150, readOnly: true, renderer: 'comma'},
          {data: 'b_price_diff',  name: '단독 B최저가<br>목표 차액', width: 150, readOnly: true, renderer: 'comma'},
          {data: 'b_price_diff_per',  name: '단독 B최저가<br>목표 차액 %', width: 150, readOnly: true, renderer: 'comma'},
          {data: 'discountAmount',  name: '쇼핑백 쿠폰<br>(발란 분담)',width: 100, readOnly: true, renderer: 'comma'},
          {data: 'epPrice',  name: 'EP송출가<br>(전회원 최대혜택가)',width: 140, readOnly: true, renderer: 'comma'},
          {data: 'rebate',  name: '수수료', width: 150, readOnly: true, renderer: 'comma'},
          {data: 'balaanDiscountAmount',  name: '발란 분담 할인', width: 150, readOnly: true, renderer: 'comma'},
          {data: 'totalMargin',  name: '매출총이익', width: 150, readOnly: true},
          {data: 'takeRate',  name: 'Take rate', width: 150, readOnly: true},
          {data: 'tot_stock',  name: '재고', width: 150, readOnly: true, type: 'numeric'},
          {data: 'status',  name: '상태', width: 150, readOnly: true, renderer: (instance, td, row, col, prop, value, cellProperties) => {
              let e = this.items.list[cellProperties.row];
              td.className = 'htCenter htMiddle';

              td.innerHTML =  `<span class="badge badge-success" title="${e.goods_status}">${{
                    registered: '등록됨',
                    processing: '등록대기',
                    terminated: '종료됨'
                  }[e.goods_status]}</span><br>`
                  + `<span class="badge badge-success" title="${e.stock_status}">${{
                    normal: '재고있음',
                    runout: '품절'
                  }[e.stock_status]}</span><br>`
                  + `<span class="badge badge-success" title="${e.display_status}">${{
                    view: '노출',
                    notview: '미노출',
                  }[e.display_status]}</span>`;
            }},
          {data: 'detail',  name: '상세', width: 150, readOnly: true, renderer: (instance, td, row, col, prop, value, cellProperties) => {
              td.className = 'htCenter htMiddle';
              td.innerHTML = `<button class="btn btn-light btn-sm">상세</button>`;
              const item = this.items.list[cellProperties.row];

              td.firstChild.onclick = _ =>  this.showModal('GoodsPriceModal', item);
            }},
        ],
        shortGoods: [
          {data: 'priceLimitNo',  name: '가격고정번호', width: 110, readOnly: true},
          {data: 'goods_no',  name: '발란코드',width: 150, readOnly: true},
          {data: 'gm_id',  name: 'GM ID',width: 150, readOnly: true},
          {data: 'gm_salePriceAvg14d',  name: 'GM 14일 평균 거래액', width: 200,readOnly: true},
          {data: 'shop_id',  name: 'SHOP ID',width: 150, readOnly: true},
          {data: 'org_price',  name: '원본가',width: 100, readOnly: true},
          {data: 'standardPrice',  name: '판매기준가', width: 150, readOnly: true},
          {data: 'partnerAddAmount',  name: '상품별 정액 할인<br>(파트너 분담)',width: 200, readOnly: true, renderer: 'comma'},
          {data: 'balaanAddAmount',  name: '상품별 정액 할인<br>(발란 분담)',width: 200, readOnly: true, renderer: 'comma'},
          {data: 'b_min_price',  name: 'B 최저가', width: 150, readOnly: true},
          {data: 'b_price_diff',  name: 'B 최저가 달성을 위한 할인액', width: 150, readOnly: true},
          {data: 'detail',  name: '상세', width: 150, readOnly: true,  renderer: (instance, td, row, col, prop, value, cellProperties) => {
              td.className = 'htCenter htMiddle';
              td.innerHTML = `<button class="btn btn-light btn-sm">상세</button>`;
              const item = this.items.list[cellProperties.row];
              td.firstChild.onclick = _ =>  this.showModal('GoodsPriceModal', item);
            }},
        ]
      }
    }
  },
  async created() {
    let monSt = moment().startOf('month');
    while (monSt >= moment().add(-12, 'month')) {
      this.months.push(monSt.format('YYYY-MM'));
      monSt.add(-1, 'month');
    }
    this.resetForm();
    this.hotSettings.columns = this.fields.goods;
    await this.list();
    this.fields.goods.forEach(f => this.colMap[f.data] = f.name)
  },
  async beforeDestroy() {
    // Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    // this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    // this.$refs.fields.saveFormFields();
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
  methods: {
    async list (more) {
      const form = this.form;
      if(form.limit > this.maxLimit) return alert('한번에 가져올 상품 수가 너무 많습니다. 25,000건 이하로 설정해주세요.');
      if (form.limit > 50000) {
        if (!form.minimal) {
          form.minimal = true;
          form.searchCol = {gm: 1};
          this.colMap = {};
          this.hotSettings.columns = this.fields.shortGoods;
          this.hotSettings.rowHeights = 32;
          this.fields.shortGoods.forEach(f => this.colMap[f.data] = f.name);
          this.$refs.hotTable.hotInstance.updateSettings(this.hotSettings);
        }

        this.$forceUpdate();
      } else {
        if (form.minimal) {
          form.minimal = false;
          form.searchCol = undefined;
          this.colMap = {};
          this.hotSettings.columns = this.fields.goods;
          this.hotSettings.rowHeights = 90;
          this.fields.goods.forEach(f => this.colMap[f.data] = f.name);
          this.$refs.hotTable.hotInstance.updateSettings(this.hotSettings);
        }
      }

      this.coupons = await this.$api.getMeta('coupon');
      this.shop_exclude = await this.$api.postMeta({type: 'shop', meta: [{name: 'shop',query: {$or: [{use_yn: 'y',
              $where: 'this.sale_base != this.supply_base'},{is_exclude_priceLimit: true}]} ,projection: {'shop_id': 1 , _id: 0}}]});
      this.makeFieldsQuery();
      const body = this.makeListFormBody();
      const j = await this.$api.postTable(this, '/price/priceLimitGoods', {...body},
          {more, fnAssign: this.assignTableData});
      // 검색결과중 검색필드와 일치하는 데이터가 있다면 url 을 바꿔준다.

      if (j) {
        if (j.list.filter(e => e.goods_no + '' === form.search).length && location.hash === '#/goods') {
          history.replaceState(null, null, location.origin + '/#/priceLimit/' + form.search);
        }
        if (j.list.length > this.maxLimit && form.sortKey === 'goods_no') {
          const index = form.sortKey === 'asc' ? 0 : j.list.length - 1
          form.last_goods_no = j.list[index].goods_no;
        } else {
          form.last_goods_no = null;
        }
      }

      this.$refs.hotTable.hotInstance.loadData(this.items.list);
    },
    assignTableData (row) {
      if (row.price_limit) {
        row.priceLimitName = row.price_limit.name;
      }
      if (row.price_table) {
        const pt = row.price_table;
        if(pt.partnerGrade) {
          row.discountBaseBalaan = -pt.partnerGrade.discountBaseBalaan || 0;
          row.discountBasePartner = -pt.partnerGrade.discountBasePartner || 0;
          row.discountCondBalaan = -pt.partnerGrade.discountCondBalaan || 0;
          row.discountCondPartner = -pt.partnerGrade.discountCondPartner || 0;
          row.discountOnlyBalaan = -pt.partnerGrade.discountOnlyBalaan || 0;
          row.totalDiscountBalaan = (row.discountBaseBalaan + row.discountCondBalaan + row.discountOnlyBalaan);
        }
        if (row.price_table.sale) {
          row.localRatePrice = row.price_table.sale.localPrice - row.price_table.sale.limitedPrice;
        }

        if (pt.priceLimitRule) {
          row.limitPrice = row.price_table.priceLimitRule.priceLimit;
        }
        row.rebate = row.price_table.rebate;
        row.balaanDiscountAmount = (row.totalDiscountBalaan || 0) + (row.balaanAddAmount || 0) - (row.discountAmount || 0);
      }
      row.c_rank = row.b_rank_class ? `${row.b_rank_class.slice(-1) === 'A' ? '단독' : '동률'} ${row.b_rank}위/${row.b_rank_total}개` : '-/-';
      row.discountAmount = row.discountAmount > 0 ? -row.discountAmount : 0;
      row.epPrice = row.price + row.discountAmount;
      row.minPriceAll = row.gm.minPriceAll ? Math.floor((row.gm.minPriceAll - 1000) / 10) * 10 : '-';
      row.minPriceAllDiff = row.minPriceAllAmount < 0 ? row.minPriceAllAmount : 0;
      row.minPriceAllDiffPer = row.minPriceAllRate < 0 ? row.minPriceAllRate+'%' : '0%';
      row.b_min_price = row.b_min_price ? row.b_rank_class === '1A' ? row.b_min_price : Math.floor((row.b_min_price - 1000) / 10) * 10 : '-';
      row.b_price_diff = row.bMinPriceAmount < -10 ? row.bMinPriceAmount : 0;
      row.b_price_diff_per = row.bMinPriceAmount < -10 ? row.bMinPriceRate+'%' : '0%';
      const totMargin = Math.round(row.rebate + row.balaanDiscountAmount);
      row.totalMargin = utils.comma(totMargin);
      row.takeRate = Math.round((totMargin / row.standardPrice) * 100 ) / 100;

      'discountBaseBalaan,discountBasePartner,discountCondBalaan,discountCondPartner,discountOnlyBalaan,totalDiscountBalaan,b_min_price'.split(',').forEach(key => {
        row[key] = row[key] || '-';
      });
    },
    makeFieldsQuery() {
      return Object.entries(this.formFields).filter(([, obj]) => {
        const field = this.form.fields[obj.key];
        // 검색값이 있을 때만 전달
        return obj.op.in('eq', 'ne', 'like') && field.value !== '' ||
            obj.op === 'enum' && field.values.length > 0 ||
            obj.op === 'range' && (field.gte !== '' || field.lte !== '') ||
            obj.op === 'exists';
      }).map(([key, obj]) => {
        const field = this.form.fields[obj.key];
        return {key, ...obj, ...field};
      });
    },
    makeListFormBody() {
      const form = this.form;

      const shop = form.shop.map(e => e.shop_id);
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);

      const fields = this.makeFieldsQuery();
      let [goods_no_include, goods_no_exclude] = this.calcIncExc(form.goods_no_include, form.goods_no_exclude);
      goods_no_include = goods_no_include.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리
      goods_no_exclude = goods_no_exclude.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리
      const [goods_id_include, goods_id_exclude] = this.calcIncExc(form.goods_id_include, form.goods_id_exclude);
      const [gm_id, gm_id_exclude] = this.calcIncExc(form.gm_id, form.gm_id_exclude);
      const [sku_include, sku_exclude] = this.calcIncExc(form.sku_include, form.sku_exclude);
      const shop_exclude = this.shop_exclude.shop;
      const coupons = this.coupons.coupon;
      const gm_status = form.isCatalog === 'ALL' ? '' : form.isCatalog.split(",");

      const body = {
        ...form,
        fields,
        shop,
        brand,
        category,
        goods_no_include,
        goods_no_exclude,
        goods_id_include,
        goods_id_exclude,
        gm_id,
        gm_id_exclude,
        sku_include,
        sku_exclude,
        shop_exclude,
        coupons,
        gm_status
      };
      if (this.query.trim()) {
        try {
          eval('body._query = {' + this.query + '}');
        } catch (e) {
          //
        }
      }
      return body;
    },
    calcIncExc(inc, exc) {
      let include = inc ? inc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      let exclude = exc ? exc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      if (include.length && exclude.length) { // 둘 다 존재시 exclude 를 include 에서 제외
        const excludeMap = this.$utils.arr2map(exclude);
        include = include.filter(e => !excludeMap[e]);
        exclude = [];
      }
      return [include, exclude];
    },
    async resetForm() {
      this.$refs.bps && await this.$refs.bps.setHideDisabled('true');
      this.$refs.cps && await this.$refs.cps.setCateOtion({finalCate: 'ALL', includeNewinSale: false, includeLastOne: false});
      this.form = this.$utils.clone(this.defaultForm);
    },
    selectAllItem() {
      let selectAll = !this.items.list.every(e => e.selected);
      this.items.list.forEach(e => e.selected = selectAll);
      this.$refs.hotTable.hotInstance.loadData(this.items.list);
    },
    async preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);

      if (!this.xlsx.selected.length) {
        return alert('다운받을 상품을 선택해주세요.');
      }

      this.$refs.json_data.value = JSON.stringify({name: `priceLimit_${this.$utils.kstDT().replace(/[ :]/g,'_')}.xlsx`, items: this.xlsx.selected});
      this.$refs.xlsx_form.submit();
    },
    showModal(type, item) {
      if (type === 'GoodsPriceModal') {
        this.$refs.GoodsPriceModal.showModal(item);
      } else if (type === 'PriceLimitModal') {
        this.$refs.PriceLimitModal.showModal();
      }
    },
    async DataUpdate() {
      this.busy.dataUpdate = true;
      let j = await this.$api.getJson('/order/reward/goodPartnerStat');
      alert('심사 결과 데이터 갱신이 요청되었습니다. 백그라운드에서 순차적으로 진행됩니다.');
      if (j) {
        this.busy.dataUpdate = false;
        await this.list();
      }
    }
  }
}

</script>
